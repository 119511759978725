


























































import { Component, Vue } from 'vue-property-decorator'
import {adminServer, s3Bucket} from "./domains";
import SubmitButton from "@/SubmitButton.vue";
import NotFound from "@/NotFound.vue";

@Component({
    components: {
        NotFound,
        SubmitButton
    }
})
export default class PayLink extends Vue {

    private instagram:string = "";
    private shippingAddress:string = "";
    private itemsToPurchase:string = "";
    private paypalName:string = "";
    private submitting:boolean = false;
    private seller:any = {};
    private notFound:boolean = false;
    private wasValidated:boolean = false;

    created() {
        fetch(s3Bucket+"/public/sellers/"+this.slug).then(r => r.json()).then((res) => {
            this.seller = res;
        }).catch(() => {
            this.notFound = true;
        })
    }

    get slug() {
        return this.$route.params.slug && this.$route.params.slug.toLowerCase().replace(/\..*$/,"");
    }

    get title() {
        return this.seller.name;
    }

    get logoSrc() {
        return this.seller.logoUrl;
    }

    get paymentLink() {
        return this.seller.paymentLink.replace(/paypal\.me/i,"paypal.com/paypalme");
    }


    submit() {
        this.wasValidated = true;
        const form = this.$refs.form as HTMLFormElement;
        if (!form.checkValidity()) {
            return;
        }

        this.instagram = (document.getElementById("instagramField") as HTMLInputElement).value;
        this.shippingAddress = (document.getElementById("shippingAddress") as HTMLInputElement).value;
        this.submitting = true;
        const message = `Expecting payment to ${this.slug} from Instagram user ${this.instagram} for ${this.itemsToPurchase}, ship to ${this.shippingAddress}`;
        fetch(`${adminServer}/payment`, {
            method: "post",
            body: JSON.stringify({
                message: message,
                instagram: this.instagram,
                shippingAddress: this.shippingAddress,
                itemsToPurchase: this.itemsToPurchase,
                paypalName: this.paypalName,
                slug: this.slug
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                document.location = this.paymentLink;
            }
            else {
                response.text().then(text => {
                    this.submitting = false;
                });
            }
        });
    }
}

