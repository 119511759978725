









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class SubmitButton extends Vue {

    @Prop(Boolean)
    submitting?:boolean;

    @Prop({default:"button"})
    type?:string;

}
