












































import {Vue,Component, Watch} from "vue-property-decorator";
import {adminServer} from "@/domains";
import SubmitButton from "./SubmitButton.vue";
import ContactEmail from "@/ContactEmail.vue";
const endpoint = adminServer + '/admin/api';


@Component({
    components: {
        SubmitButton,
        ContactEmail
    }
})
export default class About extends Vue {



}
