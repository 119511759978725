import PayLink from "./PayLink.vue";
import VueRouter from "vue-router";
import NotFound from "./NotFound.vue";
import Home from "./Home.vue";
import Activate from "@/Activate.vue";
import Signup from "./Signup.vue";
import About from "@/About.vue";


const routes = [
    {
        path: '/signup', component: Signup, name: "signup"
    }, {
        path: '/about', component: About, name: "about"
    },{
        path: '/:slug', component: PayLink, name: "paylink"
    },{
        path: '/activate/:token', component: Activate, name: "activate"
    },{
        path: "/", component: Home, name: "home"
    },{
        path: "/*", component: NotFound, name: "notfound"
    }
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
});

export default router;
