






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class NotFound extends Vue {
    @Prop(String)
    slug?:string;

}
