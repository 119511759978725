




















import { Component, Vue } from 'vue-property-decorator'
import Signup from "@/Signup.vue";

@Component({
    components: {
        Signup
    }
})
export default class Home extends Vue {

    signup() {
        this.$router.push({
            name: "signup"
        })
    }

    about() {
        this.$router.push({
            name: "about"
        })
    }

}

