






import "./scss/main.scss";

import {Component, Vue, Watch} from 'vue-property-decorator'
import {Route} from "vue-router";

@Component({
  components: {
  }
})
export default class App extends Vue {

  @Watch("$route",{immediate:true})
  onChangeRoute($newRoute:Route,$oldRoute:Route) {
    if ($oldRoute) {
      document.body.classList.remove("route-"+$oldRoute.name);
    }
    document.body.classList.add("route-"+this.$route.name);
    window.scrollTo(0,0);
  }

}
