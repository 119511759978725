
























































































import {Vue,Component, Watch} from "vue-property-decorator";
import { GraphQLClient, gql } from 'graphql-request';
import {adminServer} from "@/domains";
import SubmitButton from "./SubmitButton.vue";
import ContactEmail from "@/ContactEmail.vue";
const endpoint = adminServer + '/admin/api';

const graphQLClient = new GraphQLClient(endpoint, {

});

@Component({
    components: {
        SubmitButton,
        ContactEmail
    }
})
export default class Signup extends Vue {

    private slug:string = "";

    private shopName:string = "";

    private email:string = "";

    private logo:any = {};

    private paymentPage:string = "";

    private wasValidated = false;

    private usedSlugs:string[] = [];

    private submitting:boolean = false;

    private success:boolean = false;

    private showPaymentLinkError:boolean = false;

    get slugNotUnique() {
        return this.usedSlugs.includes(this.slug);
    }


    @Watch("slugNotUnique")
    onChangeSlugNotUnique() {
        const slugField = this.$refs.slugField as HTMLInputElement;
        if (this.slugNotUnique) {
            slugField.setCustomValidity("Slug field is not unique");
        }
        else {
            slugField.setCustomValidity("");
        }
    }

    private validatePaymentLink() {
        this.showPaymentLinkError = this.$refs.paymentPage &&
            !(this.$refs.paymentPage as HTMLInputElement).validity.valid && this.wasValidated;
    }


    async signUp() {
        this.wasValidated = true;
        const form = this.$refs.form as HTMLFormElement;
        if (!form.checkValidity() || !this.logo.img) {
            this.validatePaymentLink();
            return;
        }
        this.logo.generateBlob(
            async (blob:any) => {
                const mutation = gql`
                    mutation mutation ($item: SellerCreateInput) {
                        createSeller(data: $item) {
                          id
                          name
                          slug
                          paymentLink
                          logo {
                            publicUrl
                          }
                        }
                      }`;

                const sentSlug = this.slug;
                const variables = {
                    item: {
                        name: this.shopName,
                        email: this.email,
                        slug: this.slug,
                        paymentLink: "https://"+this.paymentPage,
                        logo: blob
                    }
                };
                try {
                    this.submitting = true;
                    await graphQLClient.request(mutation, variables);
                    this.success = true;
                }
                catch(e) {
                    const lastError:any = e;
                    if (/seller_slug_unique/.test(lastError.message)) {
                        this.usedSlugs.push(sentSlug);
                    }
                }
                finally {
                    this.submitting = false;
                }
            },
            'image/jpeg',
            0.8
        ); // 80% compressed jpeg file
    }

}
