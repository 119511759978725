






















import { Component, Vue } from 'vue-property-decorator'
import Signup from "@/Signup.vue";
import {adminServer} from "@/domains";
import ContactEmail from "@/ContactEmail.vue";

@Component({
    components: {
        ContactEmail,
        Signup
    }
})
export default class Activate extends Vue {

    private activating = true;

    private success = false;

    private name:string = "";

    private slug:string = "";

    private paymentLink:string = "";



    get token() {
        return this.$route.params.token;
    }

    get payLink() {
        return location.origin + "/" + this.slug;
    }

    get origin() {
        return location.origin;
    }

    mounted(): void {
        fetch(`${adminServer}/verify/${this.token}`, {
            method: "get",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((response:any) => {
            this.success = response.success;
            this.name = response.name;
            this.slug = response.slug;
            this.paymentLink = response.paymentLink;
        }).catch(() => {
            this.success = false;
        }).finally(() => {
            this.activating = false;
        });
    }

}

