




import "./scss/main.scss";

import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class ContactEmail extends Vue {

}
